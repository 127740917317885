import React from 'react';
import PropTypes from 'prop-types';
import Unit from './Unit';

const countSelected = line => {
  const lineChecked = line.isChecked && line.isActivity ? 1 : 0;

  if (line.childs && line.childs.length > 0) {
    return lineChecked + line.childs.reduce((acc, child) => acc + countSelected(child), 0);
  }
  return lineChecked;
};

const isFullySelected = line => {
  if (line.isActivity) {
    return line.isChecked;
  }
  return line.childs.reduce((acc, child) => acc && isFullySelected(child), true);
};

const selectAllRecursively = (line, value) => {
  line.isChecked = value;
  line.childs = line.childs?.map(child => selectAllRecursively(child, value));
  return line;
};

const recursiveToggleChange = (line, uId) => {
  if (line.uId === uId) {
    if (isFullySelected(line)) {
      return selectAllRecursively(line, false);
    }
    return selectAllRecursively(line, true);
  }
  line.childs = line.childs.map(child => recursiveToggleChange(child, uId));
  return line;
};

const updateRecursiveParents = line => {
  if (!line.isActivity) {
    line.partialSelected = false;
    line.isChecked = true;

    if (countSelected(line) === 0) {
      line.isChecked = false;
    } else if (countSelected(line) === countSelected(selectAllRecursively(JSON.parse(JSON.stringify(line)), true))) {
      line.isChecked = true;
    } else {
      line.partialSelected = true;
    }
    line.childs = line.childs.map(child => updateRecursiveParents(child));
  }
  return JSON.parse(JSON.stringify(line));
};

const recursiveStructure = (product, line, handleToggleChange, handleAccordionChange) => (
  <Unit
    key={`key_ngc_${line.isbn}_${line.uId}_${line.depth}`}
    id={`id_ngc_${line.isbn}_${line.uId}_${line.depth}`}
    isbnLaunch={product.isbnLaunch}
    unit={{ ...line }}
    onUnitCheckboxClick={() => handleToggleChange(line.uId)}
    onToggleClick={() => handleAccordionChange(line.uId)}
    isUnitChecked={!!line.isChecked}
    indeterminate={!!line.partialSelected}
    numberOfActivities={countSelected(line)}
    hasClosingToggle={!!(line.childs && line.childs.length > 0)}
  >
    {line.childs && line.childs.length > 0 && (
      <ul>{line.childs.map(child => recursiveStructure(product, child, handleToggleChange, handleAccordionChange))}</ul>
    )}
  </Unit>
);

const updateAccordionValue = (line, uId) => {
  if (line.uId === uId) {
    line.accordionOpened = !line.accordionOpened;
  } else {
    line.childs = line.childs.map(child => updateAccordionValue(child, uId));
  }
  return line;
};

function NGCLinksContainer({ product, confirmedProducts, updateNGCConfirmedProduct }) {
  const currentConfirmedProduct = confirmedProducts.find(cProduct => cProduct.isbnLaunch === product.isbnLaunch);

  const handleToggleChange = uId => {
    currentConfirmedProduct.ngcProductInformations = currentConfirmedProduct.ngcProductInformations
      .map(child => recursiveToggleChange(child, uId))
      .map(child => updateRecursiveParents(child));

    currentConfirmedProduct.partialSelected = false;
    currentConfirmedProduct.isChecked = true;

    if (currentConfirmedProduct.ngcProductInformations.every(isFullySelected)) {
      currentConfirmedProduct.isChecked = true;
    } else if (currentConfirmedProduct.ngcProductInformations.reduce((acc, line) => acc + countSelected(line), 0) > 0) {
      currentConfirmedProduct.partialSelected = true;
    } else {
      currentConfirmedProduct.isChecked = false;
    }

    updateNGCConfirmedProduct(currentConfirmedProduct);
  };

  const handleAccordionChange = uId => {
    currentConfirmedProduct.ngcProductInformations = currentConfirmedProduct.ngcProductInformations.map(child =>
      updateAccordionValue(child, uId)
    );

    updateNGCConfirmedProduct(currentConfirmedProduct);
  };

  const selectAllHandle = () => {
    const value = currentConfirmedProduct.ngcProductInformations.every(isFullySelected);

    currentConfirmedProduct.ngcProductInformations = currentConfirmedProduct.ngcProductInformations.map(child =>
      selectAllRecursively(child, !value)
    );

    currentConfirmedProduct.isChecked = !value;
    currentConfirmedProduct.partialSelected = false;

    updateNGCConfirmedProduct(currentConfirmedProduct);
  };

  return (
    <ul>
      <Unit
        key={`key_select_all_${product.isbnLaunch}`}
        id={`id_select_all_${product.isbnLaunch}`}
        unit={{ levelName: 'Select all' }}
        onUnitCheckboxClick={selectAllHandle}
        isUnitChecked={!!currentConfirmedProduct.isChecked}
        indeterminate={currentConfirmedProduct.partialSelected}
        selectAll
      />
      {currentConfirmedProduct.ngcProductInformations.map(child =>
        recursiveStructure(confirmedProducts, child, handleToggleChange, handleAccordionChange)
      )}
    </ul>
  );
}

NGCLinksContainer.propTypes = {
  isbnLaunch: PropTypes.string,
  product: PropTypes.object,
  confirmedProducts: PropTypes.array,
  updateNGCConfirmedProduct: PropTypes.func
};

export default NGCLinksContainer;
