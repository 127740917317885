import React from 'react';
import PropTypes from 'prop-types';
import { ICON_RIGHT, ICON_LEFT } from '@oup/shared-front-end/src/svg/oup/index.js';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import { usePagination, DOTS } from './usePagination';
import styles from './Pagination.scss';

function Pagination({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <nav role="navigation" aria-label="Pagination Navigation">
      <ul>
        <li>
          <Button
            variant="outline"
            icon={{
              component: <ICON_LEFT />
            }}
            size="small"
            disabled={currentPage === 1}
            onClick={onPrevious}
            name="Previous results page"
            ariaAttributes={{ label: 'Previous results page' }}
          />
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={`Dots ${index}`}>
                <div className={styles.paginationDiv} key={index}>
                  &#8230;
                </div>
              </li>
            );
          }

          return (
            <li key={pageNumber.toString()}>
              <Button
                key={index}
                variant={pageNumber === currentPage ? 'filled' : 'outline'}
                text={pageNumber.toString()}
                size="small"
                onClick={() => onPageChange(pageNumber)}
                ariaAttributes={
                  pageNumber === currentPage
                    ? { label: `Current Page, Page ${pageNumber.toString()}`, current: 'true' }
                    : { label: `Go to Page ${pageNumber.toString()}` }
                }
              />
            </li>
          );
        })}
        <li>
          <Button
            variant="outline"
            icon={{
              component: <ICON_RIGHT />
            }}
            size="small"
            disabled={currentPage === lastPage}
            onClick={onNext}
            name="Next results page"
            ariaAttributes={{ label: 'Next results page' }}
          />
        </li>
      </ul>
    </nav>
  );
}

Pagination.propTypes = {
  siblingCount: PropTypes.string,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number
};

export default Pagination;
